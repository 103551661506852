import React from 'react'
import classNames from 'classnames'

const QualityComplaintTile = ({
    active,
    disabled,
    loading,
    onClick,
    icon,
    name,
}) => {
    return (
        <div
            className={classNames('complaint-item', {
                active,
                disabled,
                loading,
            })}
            onClick={() => !loading && onClick()}
        >
            <div>
                <div className="complaint-icon">
                    <img src={icon} alt={name} />
                </div>
                {name}
            </div>
            <div className="btn">{active ? 'remove issue' : 'add issue'}</div>
        </div>
    )
}
export default QualityComplaintTile
