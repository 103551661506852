import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';

import './filters.scss';

const attribute = [
  { value: 'shelfStable', label: 'Shelf Stable' },
  { value: 'refrigerated', label: 'Refrigerated' },
  { value: 'glutenFree', label: 'Gluten free' },
  { value: 'kosher', label: 'Kosher' },
  { value: 'noMsg', label: 'No added MSG' },
  { value: 'noEdta', label: 'No EDTA' },
  { value: 'noHfcs', label: 'No HFCS' },
  { value: 'noColors', label: 'No synthetic colors' },
  { value: 'noFlavors', label: 'No artificial flavors' },
  { value: 'noPreserv', label: 'No artificial preservatives' },
  { value: 'dairyFree', label: 'Dairy free' },
  { value: 'eggFree', label: 'Egg free' },
  { value: 'peanutFree', label: 'Peanut free' },
  { value: 'treeNutFree', label: 'Tree nut free' },
  { value: 'wheatFree', label: 'Wheat free' }
]

class Filters extends Component {

    render() {

        let desktopFilters = null;
        let mobileFilters = null;

        const {
            isMobile, selections, flavors,
            product, packagings, productLines
        } = this.props;

        if (!isMobile) {
            desktopFilters = <div>
                <Select
                    isClearable
                    className="select-big"
                    classNamePrefix="select"
                    placeholder="Flavor"
                    options={flavors}
                    getOptionLabel={(i) => i.name}
                    getOptionValue={(i) => i.id}
                    value={flavors.find((f) => (f.id === selections.flavor))}
                    onChange={(opt) => this.props.onFilterChange('flavor', opt)}
                />
                <Select
                    isClearable
                    className="select-big"
                    classNamePrefix="select"
                    placeholder="Product"
                    options={product}
                    getOptionLabel={(i) => i.name}
                    getOptionValue={(i) => i.id}
                    value={product.find((f) => (f.id === selections.product))}
                    onChange={(opt) => this.props.onFilterChange('product', opt)}
                />
                <Select
                    isClearable
                    className="select-big"
                    classNamePrefix="select"
                    placeholder="Size"
                    options={packagings}
                    getOptionLabel={(i) => i.name}
                    getOptionValue={(i) => i.id}
                    value={packagings.find((f) => (f.id === selections.package))}
                    onChange={(opt) => this.props.onFilterChange('package', opt)}
                />
                <Select
                    isClearable
                    className="select-big"
                    classNamePrefix="select"
                    placeholder="Attribute"
                    options={attribute}
                />
                <Select
                    isClearable
                    className="select-big"
                    classNamePrefix="select"
                    placeholder="Product line"
                    options={productLines}
                    getOptionLabel={(i) => i.name}
                    getOptionValue={(i) => i.id}
                />
            </div>;
        }

        if (isMobile) {
            mobileFilters = <div>
                <Select
                    isMulti
                    className="select-big"
                    classNamePrefix="select"
                    placeholder="Sort results by"
                    options={flavors}
                    getOptionLabel={(i) => i.name}
                    getOptionValue={(i) => i.id}
                />
            </div>;
        }

        return (
            <div className="filters">
                {desktopFilters}
                {mobileFilters}
            </div>
        );
    }
}

Filters.defaultProps = {
    flavors: [],
    product: [],
    packagings: [],
    productLines: []
}

const filterList = PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
}));

Filters.propTypes = {
    flavors: filterList,
    product: filterList,
    selectedFlavor: PropTypes.string,
    packagings: filterList,
    productLines: filterList,

    onFilterChange: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    isMobile: state.main.isMobile
});

export default withRouter(connect(mapStateToProps)(Filters));
