import { actionTypes as types } from './constants'

export const submitComplaintAssets = (payload, callback) => ({
    type: types.SUBMIT_CUSTOMER_COMPLAINT_ASSETS_START,
    payload,
    callback,
})

export const submitComplaint = (payload) => ({
    type: types.SUBMIT_CUSTOMER_COMPLAINT_START,
    payload,
})
