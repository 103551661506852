import _clone from 'lodash/clone'
import _get from 'lodash/get'
import { actionTypes as types } from './constants'

const initialState = {
    assetsLoading: false,
    assetsSuccess: false,
    assetsError: false,

    loading: false,
    success: false,
    error: false,
    xls: null,
}

export default (state = initialState, action) => {
    const updatedState = _clone(state)

    switch (action.type) {
        case types.SUBMIT_CUSTOMER_COMPLAINT_ASSETS_START:
            updatedState.assetsLoading = true
            updatedState.assetsSuccess = false
            updatedState.assetsError = false
            break
        case types.SUBMIT_CUSTOMER_COMPLAINT_ASSETS_FAILED:
            updatedState.assetsLoading = false
            updatedState.assetsError = true
            break
        case types.SUBMIT_CUSTOMER_COMPLAINT_ASSETS_SUCCESS:
            updatedState.assetsLoading = false
            updatedState.assetsSuccess = true
            break
        case types.SUBMIT_CUSTOMER_COMPLAINT_START:
            updatedState.loading = true
            updatedState.success = false
            updatedState.error = false
            updatedState.xls = null
            break
        case types.SUBMIT_CUSTOMER_COMPLAINT_FAILED:
            updatedState.loading = false
            updatedState.error = true
            break
        case types.SUBMIT_CUSTOMER_COMPLAINT_SUCCESS:
            updatedState.loading = false
            updatedState.success = true
            updatedState.xls = _get(action.payload, 'data.xls')
            break

        default:
            break
    }

    return updatedState
}
