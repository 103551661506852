import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import InputError from '../form-parts/InputError'

// https://www.react-hook-form.com/advanced-usage/#Transform
const transformNumeric = (value = '') => {
    return String(value).replace(/\D/g, '')
}

const InputField = ({
    type = 'text',
    name,
    disabled,
    noError,
    numeric,
    children,
    ...inputProps
}) => {
    const methods = useFormContext()

    return (
        <>
            <Controller
                name={name}
                control={methods.control}
                render={({ field: { onChange, value, ...field } }) => {
                    return (
                        <input
                            {...inputProps}
                            type={type}
                            disabled={disabled}
                            {...field}
                            onChange={(e) => {
                                if (numeric) {
                                    return onChange(
                                        transformNumeric(e.target.value)
                                    )
                                }

                                onChange(e)
                            }}
                            value={numeric ? transformNumeric(value) : value}
                        />
                    )
                }}
            />
            {children}
            {!noError ? <InputError name={name} /> : null}
        </>
    )
}
export default InputField
