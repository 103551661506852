import React from 'react'

import { downloadFromArrayBuffer } from '../../helpers/utils'
import classNames from 'classnames'

const DownloadButton = ({
    className,
    filename,
    contentType,
    content,
    children,
    ...buttonProps
}) => {
    const handleClick = () => {
        downloadFromArrayBuffer({
            filename,
            contentType,
            buffer: content.data,
        })
    }

    return (
        <button
            {...buttonProps}
            className={classNames('download-button', className)}
            onClick={handleClick}
        >
            {children}
        </button>
    )
}

export default DownloadButton
