import React, { PureComponent } from 'react'
import classNames from 'classnames'

import './modal.scss'

class Modal extends PureComponent {
    render() {
        const modalClasses = {
            'modal-wrapper': true,
            visible: this.props.visible,
        }

        let closeModalButton = null

        if (this.props.onClose) {
            closeModalButton = (
                <button
                    className="modal-close"
                    onClick={(e) => {
                        e.preventDefault()
                        this.props.onClose()
                    }}
                />
            )
        }

        return (
            <div
                className={classNames(modalClasses)}
                onClick={this.props.onClose}
            >
                <div
                    className="modal-body"
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                >
                    {closeModalButton}
                    <div className="modal-content">{this.props.children}</div>
                </div>
            </div>
        )
    }
}

export default Modal
