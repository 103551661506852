import React from 'react'
import { useFormContext } from 'react-hook-form'
import InputError from '../form-parts/InputError'

const TextAreaField = ({
    name,
    disabled,
    noError,
    children,
    ...textareaProps
}) => {
    const methods = useFormContext()

    return (
        <>
            <textarea
                {...textareaProps}
                {...methods.register(name)}
                disabled={disabled}
            />
            {children}
            {!noError ? <InputError name={name} /> : null}
        </>
    )
}
export default TextAreaField
