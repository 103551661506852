import React, { useState } from 'react'
import FieldWrapper from '../../form-parts/FieldWrapper'
import InputField from '../../form-fields/InputField'
import PackageTypeField from '../../form-fields/PackageTypeField'
import QntField from '../../form-fields/QntField'
import ImageField from '../../form-fields/ImageField'
import Modal from '../../../../../components/modal/Modal'
import imgExample from '../../../../../assets/img/complaint/code-example.jpg'

const DamageFieldset = ({ prefix, disabled }) => {
    const [modalShow, setModalShow] = useState()

    return (
        <>
            <div>
                <div className="form-row">
                    <FieldWrapper
                        className="form-group one-third"
                        name={`${prefix}.productName`}
                    >
                        <label>Item/product/SKU name</label>
                        <InputField
                            name={`${prefix}.productName`}
                            disabled={disabled}
                        />
                    </FieldWrapper>
                    <FieldWrapper
                        className="form-group one-fifth"
                        name={`${prefix}.productCode`}
                    >
                        <label>
                            Ken's product code{' '}
                            <span
                                className="link no-hyph"
                                onClick={() => setModalShow(true)}
                            >
                                see example
                            </span>
                        </label>
                        <InputField
                            name={`${prefix}.productCode`}
                            disabled={disabled}
                            maxLength={8}
                        />
                    </FieldWrapper>
                    <FieldWrapper
                        className="form-group one-quarter"
                        name={`${prefix}.packageType`}
                    >
                        <label>Package type</label>
                        <PackageTypeField
                            name={`${prefix}.packageType`}
                            disabled={disabled}
                        />
                    </FieldWrapper>
                    <FieldWrapper
                        className="form-group one-sixth"
                        name={`${prefix}.qntOrdered`}
                    >
                        <label>Case quantity ordered</label>
                        <QntField
                            name={`${prefix}.qntOrdered`}
                            disabled={disabled}
                        />
                    </FieldWrapper>
                    <FieldWrapper
                        className="form-group one-sixth"
                        name={`${prefix}.qntReceived`}
                    >
                        <label>Case quantity received</label>
                        <QntField
                            name={`${prefix}.qntReceived`}
                            disabled={disabled}
                        />
                    </FieldWrapper>
                    <FieldWrapper
                        className="form-group one-fifth"
                        name={`${prefix}.qntDamaged`}
                    >
                        <label>Case quantity damaged</label>
                        <QntField
                            name={`${prefix}.qntDamaged`}
                            disabled={disabled}
                        />
                    </FieldWrapper>
                </div>
                <ImageField
                    prefix={prefix}
                    disabled={disabled}
                    title="Provide image(s) of damage"
                />
            </div>
            <Modal
                visible={modalShow}
                onClose={() => setModalShow(false)}
            >
                <h2>Example of the product code</h2>
                <p className="img-wrapper">
                    <img src={imgExample} alt="" />
                </p>
            </Modal>
        </>
    )
}

export default DamageFieldset
