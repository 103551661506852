import { complaintsList } from './config'

const complaints = complaintsList.reduce((memo, current) => {
    memo[current.key] = []

    return memo
}, {})

export const defaultValues = {
    currentDate: '',
    distrName: '',
    firstLastName: '',
    distrAddress: '',
    email: '',
    city: '',
    poNumber: '',
    state: '',
    poDeliveryDate: '',
    zip: '',
    carrierName: '',
    complaints: {
        ...complaints,
        quality: [],
    },
}
