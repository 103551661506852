import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import _get from 'lodash/get'

import FieldWrapper from '../../form-parts/FieldWrapper'
import YesNoField from '../../form-fields/YesNoField'
import InputField from '../../form-fields/InputField'
import SelectField from '../../form-fields/SelectField'
import ImageField from '../../form-fields/ImageField'
import { defaultValues } from './defaultValues'

const ResolutionFieldset = ({
    prefix,
    disabled,
    acceptLabel = 'Are you keeping the product?',
    imageFieldTitle,
}) => {
    const {
        watch,
        setValue,
        formState: { dirtyFields },
    } = useFormContext()

    const acceptValue = watch(`${prefix}.accept`)

    const notAccepted = acceptValue === false

    const descIsDirty = !!_get(dirtyFields, `${prefix}.desc`)

    useEffect(() => {
        // reset desc to default if accept changed to "true"
        if (acceptValue === true && descIsDirty) {
            setValue(`${prefix}.desc`, defaultValues.desc)
        }
    }, [acceptValue, descIsDirty, prefix, setValue])

    return (
        <>
            <div className="form-row">
                <FieldWrapper
                    className="form-group one-quarter"
                    name={`${prefix}.accept`}
                >
                    <label>{acceptLabel}</label>
                    <YesNoField name={`${prefix}.accept`} disabled={disabled} />
                </FieldWrapper>
                {notAccepted ? (
                    <>
                        <FieldWrapper
                            className="form-group one-third"
                            name={`${prefix}.desc.warehouseName`}
                        >
                            <label>Warehouse contact name</label>
                            <InputField
                                name={`${prefix}.desc.warehouseName`}
                                disabled={disabled}
                            />
                        </FieldWrapper>
                        <FieldWrapper
                            className="form-group one-quarter"
                            name={`${prefix}.desc.warehouseEmail`}
                            disabled={disabled}
                        >
                            <label>Warehouse contact email</label>
                            <InputField
                                type="email"
                                name={`${prefix}.desc.warehouseEmail`}
                                disabled={disabled}
                            />
                        </FieldWrapper>
                        <FieldWrapper
                            className="form-group one-fifth"
                            name={`${prefix}.desc.storageType`}
                        >
                            <label>Storage type</label>
                            <SelectField
                                name={`${prefix}.desc.storageType`}
                                disabled={disabled}
                                options={[
                                    {
                                        label: 'Shelf Stable',
                                        value: 'Shelf Stable',
                                    },
                                    {
                                        label: 'Refrigerated',
                                        value: 'Refrigerated',
                                    },
                                ]}
                            />
                        </FieldWrapper>
                    </>
                ) : null}
            </div>
            {notAccepted ? (
                <ImageField
                    prefix={`${prefix}.desc`}
                    disabled={disabled}
                    title={imageFieldTitle}
                />
            ) : null}
        </>
    )
}
export default ResolutionFieldset
