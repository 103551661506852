import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { screenResize } from './actions/screenResizeActions'
import { loadLookupData } from './actions/appActions'
import AppRoutes from './app.routes'

import Header from './components/header/Header'
import Footer from './components/footer/Footer'
import ModalError from './components/modal-error/ModalError'
import CookiesNotificationBanner from './components/CookiesNotificationBanner'

import './app.scss'

class App extends Component {
    constructor(props) {
        super(props)
        this.handleScreenChange = this.handleScreenChange.bind(this)
        this.handleScreenChange()
    }

    componentDidMount() {
        this.props.loadLookupData()
        window.addEventListener('resize', this.handleScreenChange)
        window.scrollTo(0, 0)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleScreenChange)
    }

    handleScreenChange() {
        const { isMobile, screenResize } = this.props
        const currentIsMobile = window.innerWidth < 1000
        if (isMobile !== currentIsMobile) {
            screenResize(currentIsMobile)
        }
    }

    handleMenuClick(route, filter) {
        this.props.history.push(route, filter)
    }

    render() {
        const { history, location, menuItems } = this.props
        const {
            location: { pathname },
        } = this.props
        const showHeader = !pathname.match('coronavirus|pizzaexpo')
        const showFooter = !pathname.match('pizzaexpo')
        // const showAlert = !pathname.match('coronavirus');

        return (
            <div className={classNames({ page: showHeader })}>
                {showHeader && (
                    <Header
                        location={location}
                        history={history}
                        menuItems={menuItems}
                        onMenuClick={(r, f) => this.handleMenuClick(r, f)}
                    />
                )}
                {/* showAlert && <Alert /> */}
                <div className="main">
                    <AppRoutes />
                </div>
                {showFooter && <Footer />}
                <ModalError />
                <CookiesNotificationBanner />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    isMobile: state.main.isMobile,
    location: state.router.location,
    menuItems: state.lookup.menuItems,
})

export default withRouter(
    connect(mapStateToProps, { screenResize, loadLookupData })(App)
)
