import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import FieldWrapper from '../../form-parts/FieldWrapper'
import InputField from '../../form-fields/InputField'
import PackageTypeField from '../../form-fields/PackageTypeField'
import QntField from '../../form-fields/QntField'
import SelectField from '../../form-fields/SelectField'
import IncidentFieldset from '../../form-fieldsets/incident-fieldset'
import { defaultValues as incidentDefaultValues } from '../../form-fieldsets/incident-fieldset/defaultValues'
import Modal from '../../../../../components/modal/Modal'
import imgExample from '../../../../../assets/img/complaint/code-example.jpg'

const incidentTitles = {
    1: 'LOT ONE',
    2: 'LOT TWO',
    3: 'LOT THREE',
    4: 'LOT FOUR',
    5: 'LOT FIVE',
}

const QualityFieldset = ({ prefix, disabled }) => {
    const methods = useFormContext()

    const [newLotsNumber, incidents] = methods.watch([
        `${prefix}.lotsNumber`,
        `${prefix}.incidents`,
    ])

    const { setValue } = methods

    useEffect(() => {
        const fieldPath = `${prefix}.incidents`

        const currentLotsNumber = incidents.length

        if (!newLotsNumber) {
            return setValue(fieldPath, [])
        }

        const diff = newLotsNumber - currentLotsNumber

        if (diff > 0) {
            const newtIncidents = Array.from({ length: diff }).map(
                () => incidentDefaultValues
            )

            return setValue(fieldPath, [...incidents, ...newtIncidents])
        }

        if (diff < 0) {
            const newtIncidents = [...incidents]

            return setValue(fieldPath, [
                ...newtIncidents.splice(
                    incidents.length - Math.abs(diff),
                    Math.abs(diff)
                ),
            ])
        }
    }, [newLotsNumber, setValue, prefix])

    const [modalShow, setModalShow] = useState()

    return (
        <>
            <div className="form-row">
                <FieldWrapper
                    className="form-group one-third"
                    name={`${prefix}.productName`}
                >
                    <label>Item/product/SKU name</label>
                    <InputField
                        name={`${prefix}.productName`}
                        disabled={disabled}
                    />
                </FieldWrapper>
                <FieldWrapper
                    className="form-group one-fifth"
                    name={`${prefix}.productCode`}
                >
                    <label>
                        Ken's product code{' '}
                        <span
                            className="link no-hyph"
                            onClick={() => setModalShow(true)}
                        >
                            see example
                        </span>
                    </label>
                    <InputField
                        name={`${prefix}.productCode`}
                        disabled={disabled}
                        maxLength={8}
                    />
                </FieldWrapper>
                <FieldWrapper
                    className="form-group one-fifth"
                    name={`${prefix}.lotsNumber`}
                >
                    <label>Number of lots affected</label>
                    <SelectField
                        name={`${prefix}.lotsNumber`}
                        disabled={disabled}
                        options={[
                            { label: '1', value: '1' },
                            { label: '2', value: '2' },
                            { label: '3', value: '3' },
                            { label: '4', value: '4' },
                            { label: '5', value: '5' },
                        ]}
                    />
                </FieldWrapper>
                <FieldWrapper
                    className="form-group one-quarter"
                    name={`${prefix}.packageType`}
                >
                    <label>Package type</label>
                    <PackageTypeField
                        name={`${prefix}.packageType`}
                        disabled={disabled}
                    />
                </FieldWrapper>
                <FieldWrapper
                    className="form-group one-sixth"
                    name={`${prefix}.caseEach`}
                >
                    <label>Case or Each</label>
                    <SelectField
                        name={`${prefix}.caseEach`}
                        disabled={disabled}
                        options={[
                            { label: 'Case', value: 'Case' },
                            { label: 'Each', value: 'Each' },
                        ]}
                    />
                </FieldWrapper>
                <FieldWrapper
                    className="form-group one-sixth"
                    name={`${prefix}.qntWithIssue`}
                >
                    <label>Case quantity with issue</label>
                    <QntField
                        name={`${prefix}.qntWithIssue`}
                        disabled={disabled}
                    />
                </FieldWrapper>
            </div>
            {incidents.map((_, idx) => {
                return (
                    <IncidentFieldset
                        key={idx}
                        prefix={`${prefix}.incidents[${idx}]`}
                        disabled={disabled}
                        title={incidentTitles[idx + 1]}
                    />
                )
            })}
            <Modal
                visible={modalShow}
                onClose={() => setModalShow(false)}
            >
                <h2>Example of the product code</h2>
                <p className="img-wrapper">
                    <img src={imgExample} alt="" />
                </p>
            </Modal>
        </>
    )
}

export default QualityFieldset
