import { defaultValues as resolutionDefaultValues } from '../../form-fieldsets/resolution-fieldset/defaultValues'

export const defaultValues = {
    shortage: {
        productName: '',
        productCode: '',
        packageType: '',
        qntOrdered: '',
        qntReceived: '',
        qntDiff: '',
    },
    overage: {
        productName: '',
        productCode: '',
        packageType: '',
        qntOrdered: '',
        qntReceived: '',
        qntDiff: '',
        resolution: { ...resolutionDefaultValues },
    },
}
