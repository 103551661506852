import React, { useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { COMPLAINTS, complaintsList, states } from './config'

import ComplaintTile from '../ComplaintTile'
import QualityComplaintTile from '../QualityComplaintTile'

import { formSchema } from '../../utils/schemas'
import { defaultValues } from './defaultValues'

import iconQuality from '../../../../assets/img/complaint/icon-quality.svg'

import * as QualityConfig from '../issue-fieldsets/quality-fieldset'

import IssueWrapper from '../IssueWrapper'
import FieldWrapper from '../form-parts/FieldWrapper'
import InputField from '../form-fields/InputField'
import SelectField from '../form-fields/SelectField'

const ComplaintForm = ({ loading, success, onSubmit }) => {
    const methods = useForm({
        mode: 'onBlur',
        defaultValues,
        resolver: yupResolver(formSchema),
    })

    const [complaints, setComplaints] = useState(
        Object.keys(COMPLAINTS).reduce((memo, current) => {
            memo[current] = false

            return memo
        }, {})
    )

    const [qualityComplaint, setQualityComplaint] = useState()

    // check if at least one complaint is added
    const isComplaintAdd = () => {
        return Object.values(complaints || {}).some(Boolean)
    }

    const toggleComplaint = (complaintId, newState) => {
        // prevent to toggle any complaints if quality complaint is active
        if (qualityComplaint) {
            return
        }

        const { resetField } = methods

        resetField(`complaints.${complaintId}`)

        setComplaints((v) => {
            return {
                ...v,
                [complaintId]:
                    // toggle or force new state
                    newState !== undefined ? !!newState : !v[complaintId],
            }
        })
    }

    const toggleQualityComplaint = (newState) => {
        // prevent to toggle quality complaint if any of complaints active
        if (isComplaintAdd()) {
            return
        }

        const { resetField } = methods

        resetField('complaints.quality')

        setQualityComplaint((v) => {
            // toggle or force new state
            return newState !== undefined ? !!newState : !v
        })
    }

    const { reset } = methods

    useEffect(() => {
        if (success) {
            // reset form
            reset()

            // close all complaint forms
            setComplaints((v) =>
                Object.keys(v || {}).reduce((memo, current) => {
                    memo[current] = false

                    return memo
                }, {})
            )

            // close quality complaint form
            setQualityComplaint(false)
        }
    }, [success, reset])

    return (
        <FormProvider {...methods}>
            <form
                className="customer-complaint-wrapper"
                onSubmit={methods.handleSubmit(onSubmit)}
            >
                <div className="core-form-wrapper">
                    <div className="two-columns">
                        <FieldWrapper className="form-group" name="currentDate">
                            <label>Today's date</label>
                            <InputField
                                type="date"
                                name="currentDate"
                                disabled={loading}
                            />
                        </FieldWrapper>
                        <FieldWrapper className="form-group" name="distrName">
                            <label>Distributor ship to name</label>
                            <InputField name="distrName" disabled={loading} />
                        </FieldWrapper>
                    </div>
                    <div className="two-columns">
                        <FieldWrapper
                            className="form-group"
                            name="firstLastName"
                        >
                            <label>Your first and last name</label>
                            <InputField
                                name="firstLastName"
                                disabled={loading}
                            />
                        </FieldWrapper>
                        <FieldWrapper
                            className="form-group"
                            name="distrAddress"
                        >
                            <label>Distributor ship to address</label>
                            <InputField
                                name="distrAddress"
                                disabled={loading}
                            />
                        </FieldWrapper>
                    </div>
                    <div className="two-columns">
                        <FieldWrapper className="form-group" name="email">
                            <label>Email address</label>
                            <InputField
                                type="email"
                                name="email"
                                disabled={loading}
                            />
                        </FieldWrapper>
                        <FieldWrapper className="form-group" name="city">
                            <label>City</label>
                            <InputField name="city" disabled={loading} />
                        </FieldWrapper>
                    </div>
                    <div className="two-columns">
                        <FieldWrapper className="form-group" name="poNumber">
                            <label>PO#</label>
                            <InputField name="poNumber" disabled={loading} />
                        </FieldWrapper>
                        <FieldWrapper className="form-group" name="state">
                            <label>State</label>
                            <SelectField
                                name="state"
                                disabled={loading}
                                options={states}
                            />
                        </FieldWrapper>
                    </div>
                    <div className="two-columns">
                        <FieldWrapper
                            className="form-group"
                            name="poDeliveryDate"
                        >
                            <label>PO delivery date</label>
                            <InputField
                                type="date"
                                name="poDeliveryDate"
                                disabled={loading}
                            />
                        </FieldWrapper>
                        <FieldWrapper className="form-group" name="zip">
                            <label>Zip code</label>
                            <InputField name="zip" disabled={loading} numeric />
                        </FieldWrapper>
                    </div>
                    <div className="two-columns">
                        <FieldWrapper className="form-group" name="carrierName">
                            <label>
                                Carrier name
                                <span className="info-tip">
                                    i
                                    <span className="info-tip-text">
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit. Curabitur faucibus
                                        egestas justo, quis feugiat erat gravida
                                        et. In vitae accumsan velit.
                                    </span>
                                </span>
                            </label>
                            <InputField name="carrierName" disabled={loading}>
                                <small>
                                    If product was picked up at a Ken's facility
                                    type in{' '}
                                    <span className="no-hyph">“picked up”</span>
                                </small>
                            </InputField>
                        </FieldWrapper>
                    </div>
                </div>

                <h3 className="text-center">Select type of complaint</h3>
                <div className="descr">
                    <small>
                        Select one or more warehouse/logistics complaint or
                        quality complaint.
                    </small>
                </div>

                <div className="separ"></div>

                <div className="complaint-items-wrapper">
                    {complaintsList.map((itm, idx) => (
                        <ComplaintTile
                            key={idx}
                            active={!!complaints[itm.key]}
                            disabled={!!qualityComplaint}
                            loading={loading}
                            onClick={() => {
                                toggleComplaint(itm.key)
                            }}
                            icon={itm.tile.icon}
                            name={itm.tile.title}
                        />
                    ))}

                    <QualityComplaintTile
                        active={!!qualityComplaint}
                        disabled={isComplaintAdd()}
                        loading={loading}
                        onClick={() => {
                            toggleQualityComplaint()
                        }}
                        icon={iconQuality}
                        name="quality"
                    />
                </div>

                <div className="complaint-list-wrapper">
                    {complaintsList
                        .filter((e) => !!complaints[e.key])
                        .map((e) => {
                            const IssueFieldsetComponent = e.fieldset.Component

                            return (
                                <IssueWrapper
                                    key={e.key}
                                    complaintKey={e.key}
                                    title={e.fieldset.title}
                                    icon={e.fieldset.icon}
                                    visible={e.fieldset.visibleClass}
                                    info={e.fieldset.info}
                                    addLabel={e.fieldset.addLabel}
                                    defaultValues={e.fieldset.defaultValues}
                                    onLastClose={() =>
                                        toggleComplaint(e.key, false)
                                    }
                                    loading={loading}
                                >
                                    {(prefix) => {
                                        return (
                                            <IssueFieldsetComponent
                                                prefix={prefix}
                                                disabled={loading}
                                            />
                                        )
                                    }}
                                </IssueWrapper>
                            )
                        })}

                    {qualityComplaint ? (
                        <IssueWrapper
                            complaintKey="quality"
                            title="quality"
                            subtitle="Each product requires a separate quality complaint. If the product has more than one lot code, each lot code needs to be submitted as a separate incident."
                            icon={iconQuality}
                            visible={'display'}
                            info="Submit a quality issue when the product does not meet Ken's standards. Each quality issue should have 1 product and 1 MFG. Add additional issues if needed."
                            addLabel={'add additional quality issue'}
                            defaultValues={QualityConfig.defaultValues}
                            onLastClose={() => toggleQualityComplaint()}
                            loading={loading}
                        >
                            {(prefix) => {
                                return (
                                    <QualityConfig.Fieldset
                                        prefix={prefix}
                                        disabled={loading}
                                    />
                                )
                            }}
                        </IssueWrapper>
                    ) : null}
                </div>

                <div className="form-action-wrapper">
                    {isComplaintAdd() || qualityComplaint ? (
                        <div className="complaint-footer">
                            <div className="submit">
                                {loading && (
                                    <button className="inverse" disabled>
                                        Sending, please wait...
                                    </button>
                                )}
                                {!loading && (
                                    <button type="submit" className="inverse">
                                        Submit request
                                    </button>
                                )}
                            </div>
                        </div>
                    ) : null}
                </div>
            </form>
        </FormProvider>
    )
}

export default ComplaintForm
