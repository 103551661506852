import React, { Suspense, lazy, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _get from 'lodash/get'
import _set from 'lodash/set'
import _cloneDeep from 'lodash/cloneDeep'

import DownloadButton from '../../components/download-button/DownloadButton'
import ComplaintForm from './components/complaint-form'
import { submitComplaintAssets, submitComplaint } from './store/actions'
import { getAssetsPathes } from './utils/helpers'

import './styles/index.scss'

const Modal = lazy(() => import('../../components/modal/Modal'))

const CustomerComplaint = ({
    loading,
    success,
    error,
    xls,
    assetsLoading,
    assetsError,
    submitComplaintAssets,
    submitComplaint,
}) => {
    const [successModalVisible, setSuccessModalVisible] = useState()

    const [errorModalVisible, seErrorModalVisible] = useState()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (success) {
            setSuccessModalVisible(true)
        }

        if (error || assetsError) {
            seErrorModalVisible(true)
        }
    }, [success, error, assetsError])

    const handleFormSubmit = useCallback(
        (values) => {
            // get array of all paths with keyword ".assets."
            const assetsPaths = getAssetsPathes(values)

            if (assetsPaths.length) {
                /*
                    Create formdata config:
                    [
                        { name: "path1 0", data: File },
                        { name: "path1 1", data: File },
                        { name: "path2 0", data: File },
                    ]
                */
                const assets = assetsPaths.reduce((memo, currentPath) => {
                    const assets = _get(values, currentPath)

                    const files = assets.map((file, i) => {
                        return {
                            name: `${currentPath} ${i}`,
                            data: file,
                        }
                    })

                    return [...memo, ...files]
                }, [])

                // send formdata to api to create assets and upload files to s3
                return submitComplaintAssets(assets, (response) => {
                    try {
                        const _values = _cloneDeep(values)

                        /*
                            Response is array of paths and file ulrs
                            [
                                { path: "path1 0", data },
                                { path: "path1 1", data },
                                { path: "path2 0", data },
                            ]
                        */

                        /*
                            Map paths to assets:
                            [
                                { path1: [data1, data2] },
                                { path2: [data1] },
                            ]
                        */
                        const newAssets = response.data.reduce(
                            (memo, currentAsset) => {
                                const path = currentAsset.path.split(' ')[0]

                                const data = currentAsset.data

                                if (memo[path]) {
                                    memo[path].push(data)
                                } else {
                                    memo[path] = [data]
                                }

                                return memo
                            },
                            {}
                        )

                        // set new assests data (urls of files) to form values
                        Object.keys(newAssets).forEach((path) => {
                            const assets = newAssets[path]

                            _set(_values, path, assets)
                        })

                        submitComplaint(_values)
                    } catch (error) {
                        console.error(error)
                    }
                })
            }

            submitComplaint(values)
        },
        [submitComplaint]
    )

    return (
        <div className="body-wrapper customer-complaint pampas">
            <h2>Ken&rsquo;s Foods customer complaint form</h2>
            <div className="descr">
                <small>All fileds are required.</small>
            </div>
            <ComplaintForm
                loading={assetsLoading || loading}
                success={success}
                onSubmit={handleFormSubmit}
            />
            <Suspense fallback={null}>
                <Modal
                    visible={successModalVisible}
                    onClose={() => setSuccessModalVisible(false)}
                >
                    <div className="submit-modal submit-modal-success">
                        <h2>Your complaint has been received.</h2>
                        {xls ? (
                            <p className="actions">
                                <DownloadButton {...xls}>
                                    Download Complaint
                                </DownloadButton>
                            </p>
                        ) : null}
                    </div>
                </Modal>
            </Suspense>
            <Suspense fallback={null}>
                <Modal
                    visible={errorModalVisible}
                    onClose={() => seErrorModalVisible(false)}
                >
                    <div className="submit-modal submit-modal-error">
                        <h2>Oops! Something went wrong. Try again.</h2>
                    </div>
                </Modal>
            </Suspense>
        </div>
    )
}

const mapStateToProps = (state) => ({
    assetsLoading: state.customerComplaint.assetsLoading,
    assetsError: state.customerComplaint.assetsError,
    loading: state.customerComplaint.loading,
    success: state.customerComplaint.success,
    error: state.customerComplaint.error,
    xls: state.customerComplaint.xls,
})

export default connect(mapStateToProps, {
    submitComplaintAssets,
    submitComplaint,
})(CustomerComplaint)
