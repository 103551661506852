import React from 'react'

import FieldWrapper from '../../form-parts/FieldWrapper'
import ImageField from '../../form-fields/ImageField'
import InputField from '../../form-fields/InputField'
import QntField from '../../form-fields/QntField'
import TextAreaField from '../../form-fields/TextAreaField'

const IncidentFieldset = ({ prefix, disabled, title = 'Incident' }) => {
    return (
        <>
            <div className="form-row">
                <div className="form-group">
                    <div className="separ light"></div>
                </div>
            </div>
            <div className="form-group">
                <h4>{title}</h4>
            </div>
            <div className="form-row">
                <FieldWrapper
                    className="form-group one-quarter"
                    name={`${prefix}.julianDate`}
                >
                    <label>Julian date</label>
                    <InputField
                        name={`${prefix}.julianDate`}
                        disabled={disabled}
                        numeric
                        maxLength={3}
                    />
                </FieldWrapper>
                <FieldWrapper
                    className="form-group one-fifth"
                    name={`${prefix}.qntReceived`}
                >
                    <label>Case quantity with issue</label>
                    <QntField
                        name={`${prefix}.qntReceived`}
                        disabled={disabled}
                    />
                </FieldWrapper>
            </div>
            <ImageField
                prefix={prefix}
                disabled={disabled}
                title="Please submit two images"
            />
            <div className="form-row">
                <FieldWrapper
                    className="form-group"
                    name={`${prefix}.comments`}
                >
                    <label>Comments</label>
                    <TextAreaField
                        name={`${prefix}.comments`}
                        disabled={disabled}
                        rows={3}
                        placeholder="Please tell us about the issue. This will help us determine the root cause of the problem."
                        noError
                    />
                </FieldWrapper>
            </div>
        </>
    )
}

export default IncidentFieldset
