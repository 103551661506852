import React from 'react'
import { useFormContext, useFormState } from 'react-hook-form'
import classNames from 'classnames'
import _get from 'lodash/get'

import { getFieldValidationClass } from '../../utils/helpers'

const FieldWrapper = ({ className, name, children }) => {
    const { control } = useFormContext()

    // https://www.react-hook-form.com/api/useformstate/
    const { errors, dirtyFields } = useFormState({ control })

    const validationClasses = name
        ? getFieldValidationClass(name, { errors, dirtyFields })
        : ''

    const errorMessage = name && _get(errors, `${name}.message`)

    return (
        <div
            title={errorMessage}
            className={classNames(className, validationClasses)}
        >
            {children}
        </div>
    )
}
export default FieldWrapper
