import React, { useState } from 'react'
import FieldWrapper from '../../form-parts/FieldWrapper'
import InputField from '../../form-fields/InputField'
import PackageTypeField from '../../form-fields/PackageTypeField'
import QntField from '../../form-fields/QntField'
import ImageField from '../../form-fields/ImageField'
import Modal from '../../../../../components/modal/Modal'
import imgExample from '../../../../../assets/img/complaint/code-example.jpg'

const ExcessFieldset = ({ prefix, disabled }) => {
    const [modalShow, setModalShow] = useState()

    return (
        <>
            <div className="form-row">
                <FieldWrapper
                    className="form-group one-third"
                    name={`${prefix}.productName`}
                >
                    <label>Item/product/SKU name</label>
                    <InputField
                        name={`${prefix}.productName`}
                        disabled={disabled}
                    />
                </FieldWrapper>
                <FieldWrapper
                    className="form-group one-fifth"
                    name={`${prefix}.productCode`}
                >
                    <label>
                        Ken's product code{' '}
                        <span
                            className="link no-hyph"
                            onClick={() => setModalShow(true)}
                        >
                            see example
                        </span>
                    </label>
                    <InputField
                        name={`${prefix}.productCode`}
                        disabled={disabled}
                        maxLength={8}
                    />
                </FieldWrapper>
                <FieldWrapper
                    className="form-group one-quarter"
                    name={`${prefix}.packageType`}
                >
                    <label>Package type</label>
                    <PackageTypeField
                        name={`${prefix}.packageType`}
                        disabled={disabled}
                    />
                </FieldWrapper>
                <FieldWrapper
                    className="form-group one-sixth"
                    name={`${prefix}.qntOrdered`}
                >
                    <label>Case quantity ordered</label>
                    <QntField
                        name={`${prefix}.qntOrdered`}
                        disabled={disabled}
                    />
                </FieldWrapper>
                <FieldWrapper
                    className="form-group one-sixth"
                    name={`${prefix}.qntToReturn`}
                >
                    <label>Total cases requested to return</label>
                    <QntField
                        name={`${prefix}.qntToReturn`}
                        disabled={disabled}
                    />
                </FieldWrapper>
                <FieldWrapper
                    className="form-group one-fifth"
                    name={`${prefix}.invoiceDate`}
                >
                    <label>Invoice date</label>
                    <InputField
                        type="date"
                        name={`${prefix}.invoiceDate`}
                        disabled={disabled}
                    />
                </FieldWrapper>
            </div>
            <ImageField prefix={prefix} disabled={disabled} />
            <div className="form-row">
                <div className="form-group">
                    <div className="separ light"></div>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group">
                    <small>
                        <strong>Terms and Conditions</strong>
                        <br />
                        If return is approved customer is responsible to arrange
                        and pay for freight. Returns must be made{' '}
                        <strong>
                            within 15 days of the invoice date.
                        </strong>{' '}
                        Product being returned must be within 100+ days of
                        product shelf life. For additional information visit{' '}
                        <a href="/terms" target="_blank">
                            Ken&rsquo;s Foodservice Return Policy
                        </a>
                        .
                    </small>
                </div>
                <div className="form-group">
                    <small>
                        Visit{' '}
                        <a href="/" target="_blank" rel="noopener noreferrer">
                            kensfoodservice.com
                        </a>
                        ,{' '}
                        <a
                            href="https://www.sbrfoodservice.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            sbrfoodservice.com
                        </a>{' '}
                        or{' '}
                        <a
                            href="https://www.kogisauce.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            kogisauce.com
                        </a>{' '}
                        to view product shelf life.
                    </small>
                </div>
            </div>
            <Modal
                visible={modalShow}
                onClose={() => setModalShow(false)}
            >
                <h2>Example of the product code</h2>
                <p className="img-wrapper">
                    <img src={imgExample} alt="" />
                </p>
            </Modal>
        </>
    )
}

export default ExcessFieldset
