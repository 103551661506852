import { defaultValues as resolutionDefaultValues } from '../../form-fieldsets/resolution-fieldset/defaultValues'

export const defaultValues = {
    productName: '',
    productCode: '',
    packageType: '',
    qntOrdered: '',
    qntReceived: '',
    qntShortShelf: '',
    resolution: { ...resolutionDefaultValues },
}
