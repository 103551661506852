import _get from 'lodash/get'
import _uniq from 'lodash/uniq'
import { flatten } from 'flat'

export const getFieldValidationClass = (name, formState) => {
    const isDirty = _get(formState.dirtyFields, name)

    const isError = _get(formState.errors, name)

    return isError ? 'is-invalid' : isDirty && !isError ? 'is-valid' : ''
}

export const getAssetsPathes = (data) => {
    const ASSET_KEY = 'assets'

    /**
     * "complaints.overage.0.resolution.desc.assets"
     * "complaints.overage.1.resolution.desc.assets"
     */
    const assetsPathRegex = new RegExp(`\.${ASSET_KEY}\.`)

    const assetsPaths = _uniq(
        Object.keys(flatten(data))
            .filter((p) => {
                return assetsPathRegex.test(p)
            })
            .map((p) => {
                return `${p.split(`.${ASSET_KEY}`)[0]}.${ASSET_KEY}`
            })
    )

    // return only paths with value
    const filteredAssetsPaths = assetsPaths.filter((path) => {
        const targetAssets = _get(data, path)

        return targetAssets && targetAssets.length
    })

    return filteredAssetsPaths
}
