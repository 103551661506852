import React, { Component, Suspense, lazy } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';

import './header.scss';

import retailBottle from '../../assets/img/ranch-retail.png';
import sbrBottle from '../../assets/img/sbr-sauce.png';
import kogiBottle from '../../assets/img/kogi-sauce.png';
import sbrRetail from '../../assets/img/sbr-retail.png';
import logo from '../../assets/img/Kens_FamilyOwned_2_DarkBlue.png';

const Navigation = lazy(() => import('../../components/navigation/Navigation'));

class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            search: '',
            searchResult: false,
            isSearchActive: false,
            isMobileMenuActive: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(prevProps) {
        const { location: { pathname } } = this.props;
        const { isSearchActive, searchResult } = this.state;

        if (prevProps.location.pathname !== pathname && isSearchActive)  {
            this.setState({ isSearchActive: !isSearchActive });
        }

        if (searchResult) {
            this.handleRedirectToSearch();
        }
    }

    toggle (prop) {
        this.setState({[prop]: !this.state[prop]});
    }

    closeMenu() {
      this.setState({ isMobileMenuActive: false })
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ searchResult: true });
        this.toggle('isSearchActive');
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleRedirectToSearch() {
        const { search } = this.state;
        const { history, location } = this.props;
        const shouldRedirect = search && (!location.search || (location.search && search !== location.search.slice(1)));

        if (shouldRedirect) {
            this.setState({ searchResult: false, search: '' });
            history.push({ pathname: '/search-result', search });
        }
    }

    render() {
        const { isMobile } = this.props;
        const { search, isSearchActive, isMobileMenuActive } = this.state;

        let desktopMenu = null;
        let mobileMenu = null;

        const searchClasses = {
            'search-block': true,
            'open': isSearchActive
        };

        const searchIconClasses = {
            'search-icon': true,
            'close': isSearchActive
        };

        const searchForm = (
            <div className={classNames(searchClasses)}>
                <form onSubmit={this.handleSubmit} onChange={this.handleChange}>
                    <input className="search-input" placeholder="What can we get you today?" type="text" name="search" defaultValue={search} autoFocus data-gtm-header-search={true} />
                    <input className="search-submit" type="submit" />
                </form>
            </div>
        );

        const extLinks = (
            <div className="ext-link">
                <span>Looking for this?</span>
                <a href="https://www.kensfoods.com/" target="_blank" rel="noopener noreferrer"><img src={retailBottle} alt="Kens Retail" /></a>
                <a href="https://www.sbrfoodservice.com/" target="_blank" rel="noopener noreferrer"><img src={sbrBottle} alt="SBR" /></a>
                <a href="https://www.kogisauce.com/" target="_blank" rel="noopener noreferrer"><img src={kogiBottle} alt="Kogi" /></a>
                <a href="https://www.sweetbabyrays.com/" target="_blank" rel="noopener noreferrer"><img src={sbrRetail} alt="SBR Retail" /></a>
            </div>
        );

        if (!isMobile) {
            desktopMenu = (
                <div className="header-wrapper desktop">
                    <a className='logo' href="/"><img src={logo} alt="" /></a>
                    <div className="main-nav">
                        <Suspense fallback={null}>
                            <Navigation menuItems={this.props.menuItems} onMenuClick={this.props.onMenuClick}/>
                        </Suspense>
                        <div className={classNames(searchIconClasses)} onClick={() => this.toggle('isSearchActive')} data-gtm-search-icon-link-a></div>
                    </div>
                    {isSearchActive && searchForm}
                    {extLinks}
                </div>
            );
        }

        if (isMobile) {
            const menuClasses = {
                'mobile-nav-wrapper': true,
                'visible': isMobileMenuActive
            }

            const menuBtnClasses = {
                'menu-btn': true,
                'active': isMobileMenuActive
            }

            mobileMenu = (
                <div className="header-wrapper mobile">
                    <div className="header-mobile">
                        <a className='logo' href="/"><img src={logo} alt="" /></a>
                        <button
                            onClick={() => this.toggle('isMobileMenuActive')}
                            className={classNames(menuBtnClasses)}>
                            <span/><span/><span/>
                        </button>
                        <div className={classNames(searchIconClasses)} onClick={() => this.toggle('isSearchActive')} data-gtm-search-icon-link-a></div>
                        {isSearchActive && searchForm}
                        <div className="clearfix"></div>
                    </div>
                    <div className={classNames(menuClasses)}>
                        <Suspense fallback={null}>
                            <Navigation menuItems={this.props.menuItems} closeCallback={() => this.closeMenu()}/>
                        </Suspense>
                        {extLinks}
                    </div>
                </div>
            );
        }

        return (
            <header id="no-print">
                {desktopMenu}
                {mobileMenu}
            </header>
        );
    }
}

const mapStateToProps = (state) => ({
    isMobile: state.main.isMobile
});

export default withRouter(connect(mapStateToProps)(Header));
