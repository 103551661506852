import React from 'react'
import InputField from './InputField'

const QntField = ({ name, disabled, ...inputProps }) => {
    return (
        <InputField
            {...inputProps}
            type="tel"
            name={name}
            disabled={disabled}
            numeric
            maxLength={8}
        />
    )
}

export default QntField
