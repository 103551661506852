import React, { useState } from 'react'
import classNames from 'classnames'
import { Controller, useFormContext, useFormState } from 'react-hook-form'

import FileUpload from '../../../../components/file-upload/FileUpload'
import Modal from '../../../../components/modal/Modal'
import imgExample from '../../../../assets/img/complaint/damaged-example.jpg'
import { getFieldValidationClass } from '../../utils/helpers'
import InputError from '../form-parts/InputError'

const ImageField = ({
    prefix,
    disabled,
    accept = {
        'image/jpeg': [],
        'image/jpg': [],
        'image/png': [],
    },
    maxFiles = 10,
    maxSize = 1e7, // 10mb in bytes
    title = 'Provide image of MFG information from case',
    description = 'Accepted files: .png, .jpg, maximum file size < 10Mb',
    noError,
}) => {
    const { control } = useFormContext()

    const { dirtyFields, errors } = useFormState({ control })

    const [modalVisible, setModalVisible] = useState()

    return (
        <>
            <div
                className={classNames('form-row', {
                    'disabled-element': disabled,
                })}
            >
                <div className="form-group">
                    <label>
                        {title}{' '}
                        <span
                            className="link"
                            onClick={() => setModalVisible(true)}
                        >
                            see example image
                        </span>
                    </label>
                    <Controller
                        name={`${prefix}.assets`}
                        control={control}
                        render={({ field }) => {
                            return (
                                <>
                                    <FileUpload
                                        className={getFieldValidationClass(
                                            field.name,
                                            { dirtyFields, errors }
                                        )}
                                        disabled={disabled}
                                        accept={accept}
                                        maxFiles={maxFiles}
                                        maxSize={maxSize}
                                        description={description}
                                        inputProps={field}
                                    />
                                    {!noError ? (
                                        <InputError name={field.name} />
                                    ) : null}
                                </>
                            )
                        }}
                    />
                </div>
            </div>
            <Modal
                visible={modalVisible}
                onClose={() => setModalVisible(false)}
            >
                <h2>Example image and instructions</h2>
                <p className="img-wrapper">
                    <img src={imgExample} alt="" />
                </p>
                <div className="descr">
                    Each complaint requires two clear and readable images:
                    <ol className="list">
                        <li>Closeup of manufacturing information</li>
                        <li>Closeup of product issue</li>
                    </ol>
                </div>
            </Modal>
        </>
    )
}

export default ImageField
