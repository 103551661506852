export const downloadFromArrayBuffer = ({ filename, contentType, buffer }) => {
    if (buffer) {
        const byteArray = new Uint8Array(buffer)
        const link = document.createElement('a')
        link.href = URL.createObjectURL(
            new Blob([byteArray], {
                type: contentType,
            })
        )
        link.download = filename || 'New File'
        link.click()
    }
    return
}
