import Select from 'react-select'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import InputError from '../form-parts/InputError'
import classNames from 'classnames'

const SelectField = ({
    className,
    type = 'text',
    name,
    disabled,
    noError,
    options,
    ...selectProps
}) => {
    const methods = useFormContext()

    return (
        <>
            <Controller
                name={name}
                control={methods.control}
                render={({ field: { onChange, value, ...field } }) => {
                    return (
                        <Select
                            className={classNames('select', className)}
                            classNamePrefix="select"
                            placeholder="Select..."
                            isDisabled={disabled}
                            onChange={(v) => onChange(v.value)}
                            value={options.find((e) => e.value === value) || ''}
                            {...selectProps}
                            {...field}
                            options={options}
                        />
                    )
                }}
            />
            {!noError ? <InputError name={name} /> : null}
        </>
    )
}
export default SelectField
