import React from 'react'
import _get from 'lodash/get'
import { useFormContext, useFormState } from 'react-hook-form'

const InputError = ({ name }) => {
    const { control } = useFormContext()

    // https://www.react-hook-form.com/api/useformstate/
    const { errors } = useFormState({ control })

    const message = _get(errors, `${name}.message`)

    if (!message) {
        return null
    }

    return <small className="input-error">{message}</small>
}
export default InputError
