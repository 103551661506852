import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import _Find from 'lodash/find';

import history from '../../history';

import './search.scss';

class Search extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            value: null,
            visible: false
        };

        this.openResults = this.openResults.bind(this);
        this.closeResults = this.closeResults.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
    }

    closeResults() {
        this.setState({ visible: false });
    }

    openResults() {
        this.setState({ visible: true });
    }

    handleOnChange(e) {
        this.setState({ value: e.target.value });
        this.props.onChange(e);
    }

    handleSubmit(e) {
        const { value } = this.state;
        e.preventDefault();
        history.push({ pathname: '/search-result', search: value });
    }

    formatProductSize(product) {
        const { details: { caseInfo: { packageType, individualUnitVal, individualUnitPercent: appender }}} = product;
        return packageType === 'cup' ?
            `${packageType} (${individualUnitVal} ${appender === 'ONZ' ? 'oz' : appender})` : packageType;
    }

    getResults() {
        const { value } = this.state;
        const { results, byType, loading } = this.props;
        const findByType = byType && results.length && !_Find(results, byType);

        if (!value) {
            return null;
        }

        if (findByType) {
            if (loading) {
                return <ul><li>Loading...</li></ul>;
            }

            if (value) {
                return <ul><li>Sorry, no search results were found</li></ul>;
            }
        }

        if (!results.length) {
            if (loading) {
                return <ul><li>Loading...</li></ul>;
            }

            if (value) {
                return <ul><li>Sorry, no search results were found</li></ul>;
            }
        }

        const generateItems = (type) => {
            const item = _Find(results, type);
            const entType = !!type.match('plusones') ? 'plus-one' : type;
            return item && item[type] && item[type].map((r, i) => (
                <li key={`res-itm-${i}-${type}`}>
                    {Array.isArray(r.label)
                    ? (
                        <Link to={{ pathname: `/${entType}/${r.slug}`, state: { productCode: r.label[1] }}} data-gtm-main-search-text={true}>
                            {r.label.map((l, x) => (
                                <span key={`res-itm-info-${i}-${x}`}>{l}</span>
                            ))}
                            <span>{this.formatProductSize(r)}</span>
                        </Link>
                    ) : (
                        <Link to={`/${entType}/${r.slug}`} data-gtm-main-search-text={true}><div>{r.label}</div></Link>
                    )}
                </li>
            ))
        };

        if (byType) {
            return value ? (
                <ul>
                    <li className="group-title">{byType}</li>
                    <ul>{generateItems(byType)}</ul>
                </ul>
            ) : null;
        } else {
            const groups = [];
            results.forEach((result, j) => Object.keys(result).forEach((g, i) => {
                const payload =
                    <ul key={`res-grp-${j}-${i}`}>
                        <li className="group-title">{g.match('plusones') ? 'Make it your own' : g}</li>
                        <ul>{generateItems(g)}</ul>
                    </ul>

                groups.push(payload);
            }));
            return groups;
        }
    }

    render() {
        const searchClasses = {
            "search-dropdown" : true,
            "visible": this.state.visible
        };

        return (
            <div className={this.props.className}>
                <form onSubmit={this.handleSubmit} data-gtm-main-search-bar={true}>
                    <input
                        type="text"
                        className="input-big"
                        onFocus={this.openResults}
                        onBlur={this.closeResults}
                        onChange={this.handleOnChange}
                        placeholder={this.props.placeholder} />
                </form>

                <div className= {classNames(searchClasses)}>
                    {this.getResults()}
                </div>

            </div>
        );
    }
}

export default Search;
