import React from 'react'

import iconOverage from '../../../../assets/img/complaint/icon-overage.svg'
import iconShortage from '../../../../assets/img/complaint/icon-shortage.svg'
import iconDamage from '../../../../assets/img/complaint/icon-damage.svg'
import iconMispick from '../../../../assets/img/complaint/icon-mispick.svg'
import iconWrong from '../../../../assets/img/complaint/icon-wrong.svg'
import iconShortLife from '../../../../assets/img/complaint/icon-short-life.svg'
import iconExcess from '../../../../assets/img/complaint/icon-excess.svg'

import * as DamageConfig from '../issue-fieldsets/damage-fieldset'
import * as ExcessConfig from '../issue-fieldsets/excess-fieldset'
import * as MisspickConfig from '../issue-fieldsets/misspick-fieldset'
import * as OverageConfig from '../issue-fieldsets/overage-fieldset'
import * as ShortageConfig from '../issue-fieldsets/shortage-fieldset'
import * as ShortshelfConfig from '../issue-fieldsets/shortshelf-fieldset'
import * as WrongproductConfig from '../issue-fieldsets/wrongproduct-fieldset'

// keys
export const COMPLAINTS = {
    overage: 'overage',
    shortage: 'shortage',
    damage: 'damage',
    mispick: 'mispick',
    wrongProduct: 'wrongProduct',
    shortShelf: 'shortShelf',
    excess: 'excess',
}

export const complaintsList = [
    {
        key: COMPLAINTS.overage,
        tile: {
            title: 'overage',
            icon: iconOverage,
        },
        fieldset: {
            title: 'overage',
            icon: iconOverage,
            visibleClass: 'display',
            info: "Submit an overage complaint when you received more cases than ordered. Please indicate if you would like Ken's Foods to pick up the product or if you want to keep the product.",
            addLabel: 'add additional overage issue',
            defaultValues: OverageConfig.defaultValues,
            Component: OverageConfig.Fieldset,
        },
    },
    {
        key: COMPLAINTS.shortage,
        tile: {
            title: 'shortage',
            icon: iconShortage,
        },
        fieldset: {
            title: 'shortage',
            icon: iconShortage,
            visibleClass: 'display',
            info: (
                <>
                    Submit a shortage complaint when we shipped fewer cases than noted on the bill of lading. <strong>Please note:</strong> Before submitting a shortage complaint, verify on the bill of lading that IS NOT a known short. Do not submit if this is a known short.
                </>
            ),
            addLabel: 'add additional shortage issue',
            defaultValues: ShortageConfig.defaultValues,
            Component: ShortageConfig.Fieldset,
        },
    },
    {
        key: COMPLAINTS.damage,
        tile: {
            title: 'damage (visible and concealed)',
            icon: iconDamage,
        },
        fieldset: {
            title: 'damage (visible and concealed)',
            icon: iconDamage,
            visibleClass: 'display',
            info: 'Submit a claim if the product shows visible damage due to mishandling.',
            addLabel: 'add additional damage issue',
            defaultValues: DamageConfig.defaultValues,
            Component: DamageConfig.Fieldset,
        },
    },
    {
        key: COMPLAINTS.mispick,
        tile: {
            title: 'mispick',
            icon: iconMispick,
        },
        fieldset: {
            title: 'mispick',
            icon: iconMispick,
            visibleClass: 'display',
            info: 'Submit a mispick if the right product was ordered, but a different product was received.',
            addLabel: 'add mispick issue',
            defaultValues: MisspickConfig.defaultValues,
            Component: MisspickConfig.Fieldset,
        },
    },
    {
        key: COMPLAINTS.wrongProduct,
        tile: {
            title: 'Wrong Product Ordered',
            icon: iconWrong,
        },
        fieldset: {
            title: 'wrong product ordered',
            icon: iconWrong,
            visibleClass: 'display',
            info: (
                <>
                    Submit a claim if the wrong product was ordered by a food
                    broker, or Ken's customer service. If this was due to a
                    distributor error, please{' '}
                    <a href="/">Submit a returns complaint</a>
                </>
            ),
            addLabel: 'add additional wrong ordered issue',
            defaultValues: WrongproductConfig.defaultValues,
            Component: WrongproductConfig.Fieldset,
        },
    },
    {
        key: COMPLAINTS.shortShelf,
        tile: {
            title: 'short shelf life',
            icon: iconShortLife,
        },
        fieldset: {
            title: 'short shelf life',
            icon: iconShortLife,
            visibleClass: 'display',
            info: (
                <>
                    Submit a claim if product shelf life is less than 2/3 of the
                    shelf life of the product. Visit{' '}
                    <a href="/" target="_blank">
                        kensfoodservice.com
                    </a>
                    ,{' '}
                    <a
                        href="https://www.sbrfoodservice.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        sbrfoodservice.com
                    </a>{' '}
                    or{' '}
                    <a
                        href="https://www.kogisauce.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        kogisauce.com
                    </a>{' '}
                    for shelf life of each product.
                </>
            ),
            addLabel: 'add additional short shelf life issue',
            defaultValues: ShortshelfConfig.defaultValues,
            Component: ShortshelfConfig.Fieldset,
        },
    },
    {
        key: COMPLAINTS.excess,
        tile: {
            title: 'Excess Inventory Return Request',
            icon: iconExcess,
        },
        fieldset: {
            title: 'excess inventory return request',
            icon: iconExcess,
            visibleClass: 'display',
            info: "Submit a claim when you have excess inventory in stock at your location. Returns must be approved by Ken's. See terms and conditions below.",
            addLabel: 'add additional excess inventory return request issue',
            defaultValues: ExcessConfig.defaultValues,
            Component: ExcessConfig.Fieldset,
        },
    },
]

export const states = [
    {
        label: 'Alabama',
        value: 'AL',
    },
    {
        label: 'Arizona',
        value: 'AZ',
    },
    {
        label: 'Arkansas',
        value: 'AR',
    },
    {
        label: 'California',
        value: 'CA',
    },
    {
        label: 'Colorado',
        value: 'CO',
    },
    {
        label: 'Connecticut',
        value: 'CT',
    },
    {
        label: 'Delaware',
        value: 'DE',
    },
    {
        label: 'District Of Columbia',
        value: 'DC',
    },
    {
        label: 'Florida',
        value: 'FL',
    },
    {
        label: 'Georgia',
        value: 'GA',
    },
    {
        label: 'Hawaii',
        value: 'HI',
    },
    {
        label: 'Idaho',
        value: 'ID',
    },
    {
        label: 'Illinois',
        value: 'IL',
    },
    {
        label: 'Indiana',
        value: 'IN',
    },
    {
        label: 'Iowa',
        value: 'IA',
    },
    {
        label: 'Kansas',
        value: 'KS',
    },
    {
        label: 'Kentucky',
        value: 'KY',
    },
    {
        label: 'Louisiana',
        value: 'LA',
    },
    {
        label: 'Maine',
        value: 'ME',
    },
    {
        label: 'Maryland',
        value: 'MD',
    },
    {
        label: 'Massachusetts',
        value: 'MA',
    },
    {
        label: 'Michigan',
        value: 'MI',
    },
    {
        label: 'Minnesota',
        value: 'MN',
    },
    {
        label: 'Mississippi',
        value: 'MS',
    },
    {
        label: 'Missouri',
        value: 'MO',
    },
    {
        label: 'Montana',
        value: 'MT',
    },
    {
        label: 'Nebraska',
        value: 'NE',
    },
    {
        label: 'Nevada',
        value: 'NV',
    },
    {
        label: 'New Hampshire',
        value: 'NH',
    },
    {
        label: 'New Jersey',
        value: 'NJ',
    },
    {
        label: 'New Mexico',
        value: 'NM',
    },
    {
        label: 'New York',
        value: 'NY',
    },
    {
        label: 'North Carolina',
        value: 'NC',
    },
    {
        label: 'North Dakota',
        value: 'ND',
    },
    {
        label: 'Ohio',
        value: 'OH',
    },
    {
        label: 'Oklahoma',
        value: 'OK',
    },
    {
        label: 'Oregon',
        value: 'OR',
    },
    {
        label: 'Pennsylvania',
        value: 'PA',
    },
    {
        label: 'Rhode Island',
        value: 'RI',
    },
    {
        label: 'South Carolina',
        value: 'SC',
    },
    {
        label: 'South Dakota',
        value: 'SD',
    },
    {
        label: 'Tennessee',
        value: 'TN',
    },
    {
        label: 'Texas',
        value: 'TX',
    },
    {
        label: 'Utah',
        value: 'UT',
    },
    {
        label: 'Vermont',
        value: 'VT',
    },
    {
        label: 'Virginia',
        value: 'VA',
    },
    {
        label: 'Washington',
        value: 'WA',
    },
    {
        label: 'West Virginia',
        value: 'WV',
    },
    {
        label: 'Wisconsin',
        value: 'WI',
    },
    {
        label: 'Wyoming',
        value: 'WY',
    },
]
