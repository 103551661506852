export const actionTypes = {
    SUBMIT_CUSTOMER_COMPLAINT_ASSETS_START:
        'SUBMIT_CUSTOMER_COMPLAINT_ASSETS_START',
    SUBMIT_CUSTOMER_COMPLAINT_ASSETS_FAILED:
        'SUBMIT_CUSTOMER_COMPLAINT_ASSETS_FAILED',
    SUBMIT_CUSTOMER_COMPLAINT_ASSETS_SUCCESS:
        'SUBMIT_CUSTOMER_COMPLAINT_ASSETS_SUCCESS',
    SUBMIT_CUSTOMER_COMPLAINT_START: 'SUBMIT_CUSTOMER_COMPLAINT_START',
    SUBMIT_CUSTOMER_COMPLAINT_FAILED: 'SUBMIT_CUSTOMER_COMPLAINT_FAILED',
    SUBMIT_CUSTOMER_COMPLAINT_SUCCESS: 'SUBMIT_CUSTOMER_COMPLAINT_SUCCESS',
}
