import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useFormContext } from 'react-hook-form'
import _cloneDeep from 'lodash/cloneDeep'

const IssueWrapper = ({
    complaintKey,
    icon,
    title,
    subtitle,
    visible,
    info,
    addLabel,
    defaultValues,
    onLastClose,
    loading,
    children,
}) => {
    const blockClasses = {
        'complaint-block': true,
        visible,
    }

    const [repeatableKey, setRepeatableKey] = useState(1)

    const valuePath = `complaints.${complaintKey}`

    const methods = useFormContext()

    const list = methods.watch(valuePath)

    const onAdd = () => {
        const newList = list.concat(_cloneDeep(defaultValues))

        methods.setValue(valuePath, newList)
    }

    const onRemove = (idx) => {
        let isLast = false

        let newList = [...list]

        if (newList.length === 1) {
            newList = []

            isLast = true
        } else {
            newList.splice(idx, 1)
        }

        methods.setValue(valuePath, newList)

        if (isLast) {
            onLastClose()
        } else {
            // force rerender
            setRepeatableKey((v) => -1 * v)
        }
    }

    useEffect(() => {
        if (!list.length) {
            onAdd()
        }
    }, [])

    return (
        <div className={classNames(blockClasses)}>
            <div className="complaint-icon">
                <img src={icon} alt="" />
            </div>
            <div className="complaint-title">
                {title}
                {info && (
                    <span className="info-tip">
                        i<span className="info-tip-text">{info}</span>
                    </span>
                )}
            </div>
            {subtitle && <div className="complaint-subtitle">{subtitle}</div>}

            {list.map((_, idx) => {
                return (
                    <div
                        className="complaint-form"
                        key={`${repeatableKey}-${idx}`}
                    >
                        {children(`${valuePath}[${idx}]`)}
                        <button
                            type="button"
                            disabled={loading}
                            className="remove-icon"
                            onClick={() => onRemove(idx)}
                        />
                    </div>
                )
            })}

            <button
                type="button"
                disabled={loading}
                onClick={onAdd}
                className="add-btn inverse"
            >
                + {addLabel}
            </button>
        </div>
    )
}

export default IssueWrapper
