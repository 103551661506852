import { takeLatest, put, call } from 'redux-saga/effects'

import ApiClient from '../../../helpers/api'
import { actionTypes as types } from './constants'

const submitComplaintAssets = function* (action) {
    try {
        const apiResponse = yield call(
            [ApiClient, ApiClient.putFile],
            '/api/customer-complaint/assets',
            action.payload
        )

        yield put({
            type: types.SUBMIT_CUSTOMER_COMPLAINT_ASSETS_SUCCESS,
            payload: apiResponse,
        })
        try {
            yield call(action.callback, apiResponse)
        } catch (err) {
            console.error(
                '[submitComplaintAssets callback error] ->',
                JSON.stringify(err)
            )
            yield put({
                type: types.SUBMIT_CUSTOMER_COMPLAINT_FAILED,
                error: err,
            })
        }
    } catch (err) {
        console.error('[submitComplaintAssets] ->', JSON.stringify(err))
        yield put({
            type: types.SUBMIT_CUSTOMER_COMPLAINT_ASSETS_FAILED,
            error: err,
        })
    }
}

const submitComplaint = function* (action) {
    try {
        const apiResponse = yield call(
            [ApiClient, ApiClient.post],
            '/api/customer-complaint',
            action.payload
        )
        yield put({
            type: types.SUBMIT_CUSTOMER_COMPLAINT_SUCCESS,
            payload: apiResponse,
        })
    } catch (err) {
        console.error('[submitComplaint] ->', JSON.stringify(err))
        yield put({ type: types.SUBMIT_CUSTOMER_COMPLAINT_FAILED, error: err })
    }
}

export default [
    takeLatest(
        types.SUBMIT_CUSTOMER_COMPLAINT_ASSETS_START,
        submitComplaintAssets
    ),
    takeLatest(types.SUBMIT_CUSTOMER_COMPLAINT_START, submitComplaint),
]
