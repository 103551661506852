import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import _sortBy from 'lodash/sortBy'
import SelectField from './SelectField'

const PackageTypeField = ({ name, disabled, packagings, ...selectProps }) => {
    const options = useMemo(() => {
        return _sortBy(
            (packagings || []).map((e) => {
                return {
                    label: e.name,
                    value: e.name,
                }
            }),
            'label'
        )
    }, [packagings])

    return (
        <SelectField
            {...selectProps}
            name={name}
            disabled={disabled}
            options={options}
        />
    )
}

const mapStateToProps = (state) => ({
    packagings: state.lookup.packagings,
})

export default connect(mapStateToProps)(PackageTypeField)
