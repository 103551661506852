import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import classNames from 'classnames'

import dropIcon from '../../assets/img/icon-dropzone.svg'
import './file-upload.scss'

function validator(file, { maxFileNameLength, uploadedFiles }) {
    if (file.name.length > maxFileNameLength) {
        return {
            code: 'name-too-large',
            message: `Name is larger than ${maxFileNameLength} characters`,
        }
    }

    if (uploadedFiles.some((f) => f.name === file.name)) {
        return {
            code: 'file-exists',
            message: `This file already exists`,
        }
    }

    return null
}

const FileUpload = ({
    className,
    multiple = true,
    maxFileNameLength = 50,
    description,
    inputProps,
    ...dropzoneProps
}) => {
    const [uploadedFiles, setUploadedFiles] = useState([])

    const onDrop = useCallback(
        (acceptedFiles) => {
            setUploadedFiles([
                ...uploadedFiles,
                ...acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                ),
            ])
        },
        [uploadedFiles]
    )

    const onRemove = useCallback(
        (file) => {
            const newFiles = [...uploadedFiles]

            newFiles.splice(newFiles.indexOf(file), 1)

            setUploadedFiles(newFiles)
        },
        [uploadedFiles, setUploadedFiles]
    )

    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        validator: (file) =>
            validator(file, { maxFileNameLength, uploadedFiles }),
        multiple,
        ...dropzoneProps,
        onDrop,
    })

    const errors = useMemo(() => {
        const errorsList = fileRejections.map(({ file, errors }) => (
            <li key={file.path}>
                <strong>
                    {file.path} - {file.size} bytes
                </strong>
                <ul className="list file-errors">
                    {errors.map((e) => (
                        <li key={e.code}>{e.message}</li>
                    ))}
                </ul>
            </li>
        ))

        return errorsList.length ? <ul>{errorsList}</ul> : null
    }, [fileRejections])

    const thumbs = useMemo(() => {
        return uploadedFiles.map((file) => (
            <div className="thumb" key={file.name}>
                <img
                    src={file.preview}
                    // Revoke data uri after image is loaded
                    onLoad={() => {
                        URL.revokeObjectURL(file.preview)
                    }}
                    alt={file.name}
                />
                <small>{file.name}</small>
                <button
                    className="remove-icon"
                    onClick={() => onRemove(file)}
                />
            </div>
        ))
    }, [uploadedFiles, onRemove])

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () =>
            uploadedFiles.forEach((file) => URL.revokeObjectURL(file.preview))
    }, [uploadedFiles])

    const { onChange, name } = inputProps

    useEffect(() => {
        onChange(uploadedFiles)
    }, [uploadedFiles, onChange])

    return (
        <div className={classNames('file-upload-field', className)}>
            <div {...getRootProps({ className: 'dropzone-wrapper' })}>
                <div className="dropzone">
                    <input {...getInputProps({ name })} />
                    <img src={dropIcon} alt="" />
                    <div>
                        <strong>Choose a file(s)</strong> or drag it here.
                    </div>
                    {description ? <small>{description}</small> : null}
                </div>
            </div>
            <div className="thumbs-container">{thumbs}</div>
            <div className="upload-errors">{errors}</div>
        </div>
    )
}

export default FileUpload
