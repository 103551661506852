import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

export const useQntDiff = ({ path1, path2, resPath }) => {
    const { setValue, watch } = useFormContext()

    const [v1, v2] = watch([path1, path2])

    useEffect(() => {
        if (v1 === '' || v2 === '') {
            setValue(resPath, '')
        } else {
            const diff = Number(v2) - Number(v1)

            const diffV = diff > 0 ? diff : 0

            setValue(resPath, String(diffV), {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true,
            })
        }
    }, [v1, v2, resPath, setValue])
}
