import { setLocale } from 'yup'
import * as yup from 'yup'
import { complaintsList } from '../components/complaint-form/config'

setLocale({
    mixed: {
        default: 'This field is invalid',
        required: 'This field is required',
        oneOf: 'This value is not valid for this field',
        notOneOf: 'This value is not valid for this field',
    },
    number: {
        integer: 'This field must be a number',
        max: ({ max }) => `This value can't be greater then ${max}`,
        min: ({ min }) => `This value can't be less than ${min}`,
        moreThan: ({ more }) => `This value must be greater then ${more}`,
        lessThan: ({ less }) => `This value must be less than ${less}`,
    },
    string: {
        length: ({ length }) =>
            `This field must be exactly ${length} characters`,
        max: ({ max }) => `The maximum length of this field ${max} characters`,
        min: ({ min }) => `The minimum length of this field ${min} characters`,
        email: 'Must be a valid email',
    },
    array: {
        min: ({ min }) =>
            `This field must have at least ${min} item${min > 1 ? 's' : ''}`,
        max: ({ max }) =>
            `This field must have less than or equal to ${max} item${
                max > 1 ? 's' : ''
            }`,
    },
})

export const DateSchema = yup.string().matches(/^\d{4}-\d{2}-\d{2}$/, {
    excludeEmptyString: true,
    message: 'This field must be a valid date',
})

export const NumericSchema = yup
    .string()
    .test('isNumeric', 'isNumeric', function (value) {
        const { path, createError } = this

        if (value === '' || value === undefined) {
            return new createError({
                path,
                message: 'This field is required',
            })
        } else if (!/^\d+$/.test(value)) {
            return new createError({
                path,
                message: 'This field is invalid',
            })
        }

        return true
    })

export const QuantitySchema = NumericSchema.max(8)

export const QuantityDiffSchema = NumericSchema.test(
    'isPositiveInteger',
    'Мust be greater than zero',
    function (value) {
        const { path, createError } = this

        const integer = parseInt(value)

        if (!integer || integer < 0) {
            return new createError({
                path,
                message: 'Мust be greater than zero',
            })
        }

        return true
    }
)

export const ImageSchema = yup
    .mixed()
    .required('required')
    .test('fileFormat', 'Only image files are allowed', (value) => {
        if (value) {
            const supportedFormats = ['image/jpeg', 'image/jpg', 'image/png']
            return supportedFormats.includes(value.type)
        }
        return true
    })
    .test(
        'fileNameLength',
        `File name must be less than 50 characters`,
        (value) => {
            if (value) {
                return value.name.length > 3 && value.name.length <= 50
            }
            return true
        }
    )
    .test('fileSize', 'File size must be less than 10MB', (value) => {
        if (value) {
            return value.size <= 1e7
        }
        return true
    })

export const AssetsSchema = yup.array().of(ImageSchema).min(1).max(10)

export const ResolutionSet = yup.object({
    accept: yup.boolean().typeError('This field is required').required(),
    desc: yup.object().when('accept', {
        is: false,
        then: (schema) =>
            schema.shape({
                warehouseName: yup.string().max(200).required(),
                warehouseEmail: yup.string().email().max(200).required(),
                storageType: yup.string().max(200).required(),
                assets: AssetsSchema,
            }),
        otherwise: (schema) => schema,
    }),
})

export const IncidentSet = yup.object({
    julianDate: NumericSchema.length(3).required(),
    qntReceived: QuantitySchema.required(),
    comments: yup.string().min(20).max(5000).required(),
    assets: AssetsSchema,
})

export const ComplaintCoreSet = yup.object({
    productName: yup.string().max(200).required(),
    productCode: yup.string().max(200).required(),
    packageType: yup.string().max(200).required(),
    qntOrdered: QuantitySchema.required(),
    qntReceived: QuantitySchema.required(),
})

const ComplaintSetMap = {
    overage: ComplaintCoreSet.shape({
        qntDiff: QuantityDiffSchema.required(),
        resolution: ResolutionSet,
    }),
    shortage: ComplaintCoreSet.shape({
        qntDiff: QuantityDiffSchema.required(),
    }),
    damage: ComplaintCoreSet.shape({
        qntDamaged: QuantitySchema.required(),
        assets: AssetsSchema,
    }),
    mispick: yup.object({
        shortage: ComplaintCoreSet.shape({
            qntDiff: QuantityDiffSchema.required(),
        }),
        overage: ComplaintCoreSet.shape({
            qntDiff: QuantityDiffSchema.required(),
            resolution: ResolutionSet,
        }),
    }),
    wrongProduct: ComplaintCoreSet.shape({
        qntWrong: QuantitySchema.required(),
        resolution: ResolutionSet,
    }),
    shortShelf: ComplaintCoreSet.shape({
        qntShortShelf: QuantitySchema.required(),
        resolution: ResolutionSet,
    }),
    excess: ComplaintCoreSet.omit(['qntReceived']).shape({
        qntToReturn: QuantitySchema.required(),
        invoiceDate: DateSchema.required(),
        assets: AssetsSchema,
    }),
    quality: ComplaintCoreSet.omit(['qntReceived', 'qntOrdered']).shape({
        lotsNumber: QuantitySchema.required(),
        caseEach: yup.string().max(200).required(),
        qntWithIssue: QuantitySchema.required(),
        incidents: yup.array(IncidentSet).min(1),
    }),
}

const complaints = complaintsList.reduce((memo, current) => {
    memo[current.key] = yup.array().of(ComplaintSetMap[current.key])

    return memo
}, {})

export const formSchema = yup
    .object({
        currentDate: DateSchema.required(),
        distrName: yup.string().max(200).required(),
        firstLastName: yup.string().max(200).required(),
        distrAddress: yup.string().max(200).required(),
        email: yup.string().email().max(200).required(),
        city: yup.string().max(200).required(),
        poNumber: yup.string().max(200).required(),
        state: yup.string().max(2).required(),
        poDeliveryDate: DateSchema.required(),
        zip: NumericSchema.max(20).required(),
        carrierName: yup.string().max(200).required(),

        complaints: yup.object({
            ...complaints,
            quality: yup.array().of(ComplaintSetMap.quality),
        }),
    })
    .required()
