import React, { useState } from 'react'

import FieldWrapper from '../../form-parts/FieldWrapper'
import InputField from '../../form-fields/InputField'
import PackageTypeField from '../../form-fields/PackageTypeField'
import QntField from '../../form-fields/QntField'
import ResolutionFieldset from '../../form-fieldsets/resolution-fieldset'
import { useQntDiff } from '../../../utils/hooks'
import Modal from '../../../../../components/modal/Modal'
import imgExample from '../../../../../assets/img/complaint/code-example.jpg'

const MisspickFieldset = ({ prefix, disabled }) => {
    useQntDiff({
        path1: `${prefix}.shortage.qntReceived`,
        path2: `${prefix}.shortage.qntOrdered`,
        resPath: `${prefix}.shortage.qntDiff`,
    })

    useQntDiff({
        path1: `${prefix}.overage.qntOrdered`,
        path2: `${prefix}.overage.qntReceived`,
        resPath: `${prefix}.overage.qntDiff`,
    })

    const [modalShow, setModalShow] = useState()

    return (
        <>
            <div className="form-group">
                <h4>Mispick Shortage</h4>
            </div>
            <div className="form-row">
                <FieldWrapper
                    className="form-group one-third"
                    name={`${prefix}.shortage.productName`}
                >
                    <label>Item/product/SKU name</label>
                    <InputField
                        name={`${prefix}.shortage.productName`}
                        disabled={disabled}
                    />
                </FieldWrapper>
                <FieldWrapper
                    className="form-group one-fifth"
                    name={`${prefix}.shortage.productCode`}
                >
                    <label>
                        Ken's product code{' '}
                        <span
                            className="link no-hyph"
                            onClick={() => setModalShow(true)}
                        >
                            see example
                        </span>
                    </label>
                    <InputField
                        name={`${prefix}.shortage.productCode`}
                        disabled={disabled}
                        maxLength={8}
                    />
                </FieldWrapper>
                <FieldWrapper
                    className="form-group one-quarter"
                    name={`${prefix}.shortage.packageType`}
                >
                    <label>Package type</label>
                    <PackageTypeField
                        name={`${prefix}.shortage.packageType`}
                        disabled={disabled}
                    />
                </FieldWrapper>
                <FieldWrapper
                    className="form-group one-sixth"
                    name={`${prefix}.shortage.qntOrdered`}
                >
                    <label>Case quantity ordered</label>
                    <QntField
                        name={`${prefix}.shortage.qntOrdered`}
                        disabled={disabled}
                    />
                </FieldWrapper>
                <FieldWrapper
                    className="form-group one-sixth"
                    name={`${prefix}.shortage.qntReceived`}
                >
                    <label>Case quantity received</label>
                    <QntField
                        name={`${prefix}.shortage.qntReceived`}
                        disabled={disabled}
                    />
                </FieldWrapper>
                <FieldWrapper
                    className="form-group one-fifth"
                    name={`${prefix}.shortage.qntDiff`}
                >
                    <label>Case quantity shorted/ missing</label>
                    <QntField
                        className="read-only"
                        name={`${prefix}.shortage.qntDiff`}
                        disabled={true}
                        noError
                    />
                </FieldWrapper>
            </div>
            <div className="form-row">
                <div className="form-group">
                    <div className="separ light"></div>
                </div>
            </div>
            <div className="form-group">
                <h4>Mispick Overage</h4>
            </div>
            <div className="form-row">
                <FieldWrapper
                    className="form-group one-third"
                    name={`${prefix}.overage.productName`}
                >
                    <label>Item/product/SKU name</label>
                    <InputField
                        name={`${prefix}.overage.productName`}
                        disabled={disabled}
                    />
                </FieldWrapper>
                <FieldWrapper
                    className="form-group one-fifth"
                    name={`${prefix}.overage.productCode`}
                >
                    <label>
                        Ken's product code{' '}
                        <span
                            className="link no-hyph"
                            onClick={() => setModalShow(true)}
                        >
                            see example
                        </span>
                    </label>
                    <InputField
                        name={`${prefix}.overage.productCode`}
                        disabled={disabled}
                        maxLength={8}
                    />
                </FieldWrapper>
                <FieldWrapper
                    className="form-group one-quarter"
                    name={`${prefix}.overage.packageType`}
                >
                    <label>Package type</label>
                    <PackageTypeField
                        name={`${prefix}.overage.packageType`}
                        disabled={disabled}
                    />
                </FieldWrapper>
                <FieldWrapper
                    className="form-group one-sixth"
                    name={`${prefix}.overage.qntOrdered`}
                >
                    <label>Case quantity ordered</label>
                    <QntField
                        name={`${prefix}.overage.qntOrdered`}
                        disabled={disabled}
                    />
                </FieldWrapper>
                <FieldWrapper
                    className="form-group one-sixth"
                    name={`${prefix}.overage.qntReceived`}
                >
                    <label>Case quantity received</label>
                    <QntField
                        name={`${prefix}.overage.qntReceived`}
                        disabled={disabled}
                    />
                </FieldWrapper>
                <FieldWrapper
                    className="form-group one-fifth"
                    name={`${prefix}.overage.qntDiff`}
                >
                    <label>Case quantity over shipped</label>
                    <QntField
                        className="read-only"
                        name={`${prefix}.overage.qntDiff`}
                        disabled={true}
                        noError
                    />
                </FieldWrapper>
            </div>
            <ResolutionFieldset
                prefix={`${prefix}.overage.resolution`}
                disabled={disabled}
            />
            <Modal
                visible={modalShow}
                onClose={() => setModalShow(false)}
            >
                <h2>Example of the product code</h2>
                <p className="img-wrapper">
                    <img src={imgExample} alt="" />
                </p>
            </Modal>
        </>
    )
}

export default MisspickFieldset
