import React from 'react'
import SelectField from './SelectField'

const YesNoField = ({ name, disabled, ...selectProps }) => {
    return (
        <SelectField
            {...selectProps}
            name={name}
            disabled={disabled}
            options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
            ]}
        />
    )
}
export default YesNoField
